import React, {
  Fragment,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Location } from "@reach/router";
import Markdown from "@input-output-hk/front-end-core-components/components/Markdown";
import Footer from "@input-output-hk/front-end-site-components/components/Footer";
import Theme from "@input-output-hk/front-end-core-components/components/Theme";
import Box from "@material-ui/core/Box";
import TinyColor from "@ctrl/tinycolor";
import YouTube from "react-youtube";
import {
  FaChevronRight,
  FaChevronDown,
  FaEllipsisH,
  FaChevronUp,
  FaGithub,
  FaExternalLinkAlt,
} from "react-icons/fa";
import Link from "@input-output-hk/front-end-core-components/components/Link";
import Layout from "../components/Layout";
import Blank from "./Blank";
import { FIXED_HEADER_OFFSET } from "../constants";
import GlobalContentQuery from "../queries/GlobalContentQuery";
import MarkdownComponents from "../components/MarkdownComponents";
import Container from "../components/Container";
import config from "../config";

const PageContent = styled.div`
  display: flex;
  margin-bottom: 12rem;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 6rem;
  }
`;

const SideNavigationContainer = styled(Box)`
  padding: 2rem 0;
  flex-basis: 20%;
  border-right: 0.1rem solid
    ${({ theme }) =>
      new TinyColor(theme.palette.text.primary).setAlpha(0.2).toString()};
  min-height: ${({ navigationheights }) =>
    (navigationheights.min || 0) / 10 + 4}rem;

  > div {
    max-height: ${({ navigationheights }) =>
      navigationheights.max ? `${navigationheights.max / 10}rem` : "none"};
    overflow-y: auto;
    scrollbar-width: thin;
    padding-right: 2rem;
    max-width: ${({ maxWidth }) =>
      maxWidth === null ? "none" : `${maxWidth / 10}rem`};

    &::-webkit-scrollbar {
      width: 0.7rem;
    }

    &::-webkit-scrollbar-track {
      background: ${({ theme }) =>
        new TinyColor(theme.palette.text.primary).setAlpha(0.2).toString()};
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) =>
        new TinyColor(theme.palette.text.primary).setAlpha(0.5).toString()};
      border-radius: 0.35rem;
    }
  }

  &.position-fixed > div {
    position: fixed;
    top: ${(FIXED_HEADER_OFFSET + 20) / 10}rem;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-basis: 30%;
  }

  &.position-bottom {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;

    &.position-bottom {
      display: none;
    }
  }
`;

const MainContent = styled.div`
  padding-left: 4rem;
  flex-basis: 80%;
  max-width: 80%;
  flex-shrink: 2;

  &.no-nav {
    padding-left: 0;
    flex-basis: 100%;
    max-width: 100%;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-basis: 70%;
    max-width: 70%;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-left: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
`;

const Accordion = styled.div`
  max-height: 0;
  overflow: hidden;

  &.expanded {
    max-height: none;
  }

  > ul {
    padding-top: 0.5rem;
  }
`;

const Nav = styled.ul`
  list-style: none;
  margin: 0;

  &.position-top {
    position: static;
  }

  li {
    margin: 1rem 0;

    p {
      text-decoration: underline;
    }

    a {
      &.active {
        text-decoration: underline;
      }
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    ul {
      margin-left: 1rem;
      padding-left: 1rem;
      border-left: 0.1rem solid
        ${({ theme }) =>
          new TinyColor(theme.palette.text.primary).setAlpha(0.2).toString()};
    }
  }
`;

const AccordionToggle = styled(Link)`
  position: relative;
  padding-right: 2rem;
  display: block;

  &.has-no-content {
    color: ${({ theme }) => theme.palette.text.primary};

    &:hover {
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }

  &.active {
    font-weight: 600;
  }
`;

const MarkdownContent = styled.article`
  word-break: break-word;
  max-width: 80rem;
  display: block;
  overflow: hidden;

  sup[id*="fn"] {
    padding-top: 109px;
    margin-top: -109px;
    display: inline-block;
    vertical-align: top;
  }
  a[name*="footnote"]:before {
    content: "";
    display: block;
    height: 109px;
    margin: -109px 0 0;
  }

  blockquote {
    margin: 1rem 1rem 1rem 2rem;
    padding: 0 0 0 2rem;
    border-left: 0.1rem solid
      ${({ theme }) =>
        new TinyColor(theme.palette.text.primary).setAlpha(0.4).toString()};

    ${({ theme }) => theme.breakpoints.down("xs")} {
      margin-left: 1rem;
      padding-left: 1rem;
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: none;
  }
`;

const MobileInlineNavigation = styled.div`
  padding: 1rem 0 0 2rem;
  border-left: 0.2rem solid ${({ theme }) => theme.palette.primary.main};
  max-width: 40rem;

  > div {
    max-height: 10rem;
    overflow: hidden;
  }

  &.open > div {
    max-height: none;
    overflow: auto;
  }

  > a {
    display: inline-block;
    margin-top: 1rem;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;

const ReportAnIssueLink = styled(Link)`
  display: flex;
`;

const LastUpdated = styled.div`
  p {
    margin: 0;
  }
`;

const ExternalLink = styled(Link)`
  display: inline-block;
`;

export const StyledNextPrevious = styled.div`
  margin: 0px;
  padding: 25px 0;
  width: auto;
  display: grid;
  grid-template-rows: auto;
  column-gap: 24px;
  grid-template-columns: calc(50% - 100px) calc(50% - 100px);

  .previousBtn {
    cursor: pointer;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 3px;
    border: 1px solid rgb(230, 236, 241);
    transition: border 200ms ease 0s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px 0px;
    text-decoration: none;

    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
  }

  .nextBtn {
    cursor: pointer;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
    border-radius: 3px;
    border: 1px solid rgb(230, 236, 241);
    transition: border 200ms ease 0s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px 0px;
    text-decoration: none;

    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
  }

  .nextText,
  .prevText {
    font-size: 13px;
    color: grey;
  }

  .nextBtn:hover,
  .previousBtn:hover {
    text-decoration: none;
    border: 1px solid rgba(82, 129, 247, 1);
  }

  .nextBtn:hover .rightArrow,
  .previousBtn:hover .leftArrow {
    color: rgba(82, 129, 247, 1);
  }

  .leftArrow {
    display: block;
    margin: 0px;
    color: rgb(157, 170, 182);
    flex: 0 0 auto;
    font-size: 24px;
    transition: color 200ms ease 0s;
    padding: 16px;
    padding-right: 16px;
  }

  .rightArrow {
    flex: 0 0 auto;
    font-size: 24px;
    transition: color 200ms ease 0s;
    padding: 16px;
    padding-left: 16px;
    display: block;
    margin: 0px;
    color: rgb(157, 170, 182);
  }

  .nextPreviousTitle {
    display: block;
    margin: 0px;
    padding: 0px;
    transition: color 200ms ease 0s;
  }

  .nextPreviousTitle span {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
  }

  @media (max-width: 767px) {
    display: block;

    .previousBtn {
      margin-bottom: 20px;
    }
  }
`;

const NavigationTree = ({
  items,
  lang,
  path,
  ariaLabel,
  currentPathname,
  id = "",
  isRoot = true,
  position,
  setPosition,
  navigationHeights,
  setNavigationHeights,
  autoScroll = true,
  maxWidth,
  setMaxWidth,
}) => {
  const rootRef = useRef(null);
  const [expanded, setExpanded] = useState(getDefaultExpanded());

  function isActive(path) {
    const resolvedPath = lang ? `/${lang}${path}` : path;
    if (currentPathname.substring(0, resolvedPath.length) === resolvedPath)
      return true;
    return false;
  }

  function getDefaultExpanded() {
    const expanded = {};
    const itemsWithChildren = items.filter(
      ({ children }) => children.length > 0
    );
    itemsWithChildren.forEach((item) => {
      expanded[item.path] = isActive(item.path);
    });

    return expanded;
  }

  const updateNavigationHeights = useCallback(() => {
    const { bottom, top } = rootRef.current.getBoundingClientRect();
    const { min, max } = navigationHeights;
    const newMax = window.innerHeight - FIXED_HEADER_OFFSET - 40;
    const newMin = Math.min(Math.abs(top - bottom), newMax);
    if (min !== newMin || max !== newMax)
      setNavigationHeights({ min: newMax, max: newMax });
  }, [navigationHeights, rootRef]);

  const updateMaxWidth = useCallback(() => {
    const { left, right } =
      rootRef.current.parentElement.parentElement.getBoundingClientRect();
    const newMaxWidth = Math.abs(right - left);
    if (maxWidth !== newMaxWidth) setMaxWidth(newMaxWidth);
  }, [maxWidth, rootRef]);

  const toggleAccordion = (item) => (e) => {
    if (item.hasContent) return;
    e.preventDefault();
    if (isActive(item.path)) return;
    setExpanded({
      ...expanded,
      [item.path]: !expanded[item.path],
    });
  };

  const onScroll = useCallback(() => {
    const { top, bottom } =
      rootRef.current.parentElement.parentElement.getBoundingClientRect();
    const { bottom: navBottom, top: navTop } =
      rootRef.current.getBoundingClientRect();
    if (position === "top" && top <= 0 + FIXED_HEADER_OFFSET) {
      setPosition("fixed");
    } else if (position !== "top" && top > 0 + FIXED_HEADER_OFFSET) {
      setPosition("top");
    } else if (position !== "bottom" && navBottom >= bottom - 20) {
      setPosition("bottom");
    } else if (position === "bottom" && navTop >= 0 + FIXED_HEADER_OFFSET) {
      setPosition("fixed");
    }
  }, [position, rootRef, navigationHeights]);

  useEffect(() => {
    if (isRoot && rootRef.current && autoScroll) {
      updateNavigationHeights();
      updateMaxWidth();
      window.addEventListener("resize", updateNavigationHeights);
      window.addEventListener("resize", updateMaxWidth);
      window.addEventListener("scroll", onScroll);
      window.addEventListener("touchmove", onScroll);
    }

    return () => {
      if (isRoot && rootRef.current && autoScroll) {
        window.removeEventListener("resize", updateNavigationHeights);
        window.removeEventListener("resize", updateMaxWidth);
        window.removeEventListener("scroll", onScroll);
        window.removeEventListener("touchmove", onScroll);
      }
    };
  }, [isRoot, rootRef, position, expanded, autoScroll]);

  return (
    <Nav
      id={id}
      role="navigation"
      aria-label={ariaLabel}
      key={path}
      ref={rootRef}
      className={isRoot ? `position-${position}` : ""}
    >
      {items.map((item) => (
        <li key={item.path}>
          {item.children.length === 0 && !item.externalHref && (
            <Link
              href={`${item.path}`}
              activeClassName="active"
              title={item.title}
              partiallyActive
              tracking={{ category: "article_navigation", label: item.path }}
            >
              {item.title}
            </Link>
          )}
          {item.externalHref && (
            <ExternalLink
              href={`${item.externalHref}`}
              title={item.title}
              tracking={{
                category: "article_navigation_external",
                label: item.externalHref,
              }}
            >
              <Box display="flex">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  {item.title}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  marginLeft={1}
                >
                  <FaExternalLinkAlt />
                </Box>
              </Box>
            </ExternalLink>
          )}
          {item.children.length > 0 && !item.externalHref && (
            <Fragment>
              <AccordionToggle
                href={item.hasContent ? item.path : "#"}
                className={item.hasContent ? "" : "has-no-content"}
                onClick={toggleAccordion(item)}
                activeClassName="active"
                partiallyActive
                tracking={{
                  category: "article_navigation",
                  label: `toggle_accordion_${item.path}`,
                }}
                aria-disabled={isActive(item.path) ? "true" : "false"}
                aria-controls={item.path}
                aria-expanded={expanded[item.path]}
              >
                <Box display="flex">
                  <Box
                    flex={1}
                    justifyContent="center"
                    flexDirection="column"
                    display="flex"
                  >
                    {item.title}
                  </Box>
                  <Box
                    marginLeft={0.8}
                    justifyContent="center"
                    flexDirection="column"
                    display="flex"
                  >
                    {expanded[item.path] ? (
                      <FaChevronDown />
                    ) : (
                      <FaChevronRight />
                    )}
                  </Box>
                </Box>
              </AccordionToggle>
              <Accordion
                role="region"
                className={expanded[item.path] ? "expanded" : ""}
              >
                <NavigationTree
                  aria-labelledby={item.path}
                  ariaLabel={`${item.title} subnavigation`}
                  items={item.children}
                  path={item.path}
                  lang={lang}
                  currentPathname={currentPathname}
                  isRoot={false}
                  position={position}
                  navigationHeights={navigationHeights}
                  setNavigationHeights={setNavigationHeights}
                  setPosition={setPosition}
                />
              </Accordion>
            </Fragment>
          )}
        </li>
      ))}
    </Nav>
  );
};

NavigationTree.propTypes = {
  items: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  id: PropTypes.string,
  currentPathname: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  isRoot: PropTypes.bool,
  position: PropTypes.oneOf(["top", "fixed", "bottom"]),
  setPosition: PropTypes.func,
  navigationHeights: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  setNavigationHeights: PropTypes.func,
  maxWidth: PropTypes.number,
  setMaxWidth: PropTypes.func,
  autoScroll: PropTypes.bool,
};

const Article = ({ pageContext }) => {
  const [position, setPosition] = useState("top");
  const [navigationHeights, setNavigationHeights] = useState({
    min: null,
    max: null,
  });
  const [maxWidth, setMaxWidth] = useState(null);
  const [mobileTopNavigationOpen, setMobileTopNavigationOpen] = useState(false);
  const [mobileBottomNavigationOpen, setMobileBottomNavigationOpen] =
    useState(false);

  function getReportIssueHref({ pathname, query, hash }) {
    const baseHref = `https://github.com/${config.gitHubRepository}/issues/new?assignees=&labels=content&template=content-issue.md&title=`;
    return `${baseHref}${encodeURIComponent(
      `Invalid content ${pathname}${query || ""}${hash || ""}`
    )}`;
  }

  /**
   * Replaces references to custom components with rendered component
   * e.g. <!-- include components/OtherComponent --> -> renders components/MarkdownComponents/OtherComponent if it exists
   * e.g. <!-- embed youtube/123 --> -> Renders embedded youtube video with id 123
   */
  function renderArticleContent() {
    let remainingContent = pageContext.content;
    const contentParts = [];
    // Matches <!-- include components/<MyComponent> --> - where <MyComponent> is Alpha string reference to component
    // Or <!-- embed youtube/id --> - where id is the YouTube video id to embed
    // in src/components/MarkdownComponent/index.js
    const pattern =
      /<!--\s(include|embed)\s(components|youtube)\/([^\s]+)\s-->/;
    let match = remainingContent.match(pattern);
    let matchIndex = match ? match.index : -1;

    while (remainingContent.length > 0 && matchIndex >= 0) {
      if (matchIndex > 0) {
        contentParts.push(
          <Markdown source={remainingContent.substring(0, matchIndex)} />
        );
      }

      const [_, type, category, value] = match;
      if (type === "include" && category === "components") {
        const Component = MarkdownComponents[value];
        if (Component) contentParts.push(<Component />);
      } else if (type === "embed" && category === "youtube" && value) {
        contentParts.push(
          <YouTube
            videoId={value}
            opts={{
              width: "100%",
              height: "350px",
            }}
          />
        );
      }

      remainingContent = remainingContent.substring(
        matchIndex + match[0].length
      );
      match = remainingContent.match(pattern);
      matchIndex = match ? match.index : -1;
    }

    if (remainingContent)
      contentParts.push(<Markdown source={remainingContent} />);

    return (
      <Fragment>
        {contentParts.map((content, index) => (
          <Fragment key={index}>{content}</Fragment>
        ))}
      </Fragment>
    );
  }

  // const nav = forcedNavOrder
  //   .reduce((acc, cur) => {
  //     return acc.concat(navItems[cur]);
  //   }, [])
  //   .concat(navItems.items)
  //   .map((slug) => {
  //     if (slug) {
  //       const { node } = allMdx.edges.find(
  //         ({ node }) => node.fields.slug === slug
  //       );

  //       return { title: node.fields.title, url: node.fields.slug };
  //     }
  //   });

  // console.log(pageContext.navigationContext.children);

  const currIndex = pageContext.navigationContext.children
    .map(function (x) {
      return x.title;
    })
    .indexOf(pageContext.pageTitle);

  return (
    <GlobalContentQuery
      render={(content) => (
        <Layout
          template={Blank}
          headData={{
            title: pageContext.pageTitle,
            meta: [{ name: "description", content: "" }],
          }}
        >
          <Container>
            <Location>
              {({ location }) => (
                <PageContent>
                  {pageContext.navigationContext.children.length > 0 && (
                    <SideNavigationContainer
                      navigationheights={navigationHeights}
                      maxWidth={maxWidth}
                      className={`position-${position}`}
                    >
                      <div>
                        <NavigationTree
                          ariaLabel={`${pageContext.navigationContext.title} subnavigation`}
                          lang={pageContext.lang}
                          items={pageContext.navigationContext.children}
                          path={`/${pageContext.navigationContext.key}`}
                          currentPathname={location.pathname}
                          position={position}
                          setPosition={setPosition}
                          navigationHeights={navigationHeights}
                          setNavigationHeights={setNavigationHeights}
                          maxWidth={maxWidth}
                          setMaxWidth={setMaxWidth}
                        />
                      </div>
                    </SideNavigationContainer>
                  )}
                  <MainContent
                    className={
                      pageContext.navigationContext.children.length === 0
                        ? "no-nav"
                        : ""
                    }
                  >
                    {pageContext.navigationContext.children.length > 0 && (
                      <MobileInlineNavigation
                        className={mobileTopNavigationOpen ? "open" : ""}
                      >
                        <div>
                          <NavigationTree
                            lang={pageContext.lang}
                            ariaLabel={`${pageContext.navigationContext.title} subnavigation`}
                            items={pageContext.navigationContext.children}
                            path={`/${pageContext.navigationContext.key}`}
                            currentPathname={location.pathname}
                            autoScroll={false}
                          />
                        </div>
                        <Link
                          href="#"
                          aria-hidden="true"
                          tracking={{
                            label: "toggle_mobile_article_navigation_top",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setMobileTopNavigationOpen(
                              !mobileTopNavigationOpen
                            );
                          }}
                        >
                          {mobileTopNavigationOpen && <FaChevronUp />}
                          {!mobileTopNavigationOpen && <FaEllipsisH />}
                        </Link>
                      </MobileInlineNavigation>
                    )}
                    <MarkdownContent>{renderArticleContent()}</MarkdownContent>
                    <Box marginTop={2} marginBottom={2}>
                      {pageContext.lastUpdatedFormatted && (
                        <LastUpdated>
                          <p>
                            <small>
                              <em>
                                {content.last_updated}:{" "}
                                {pageContext.lastUpdatedFormatted}
                              </em>
                            </small>
                          </p>
                        </LastUpdated>
                      )}
                      <StyledNextPrevious>
                        {pageContext.navigationContext.children[
                          currIndex - 1
                        ] ? (
                          <Link
                            className={"previousBtn"}
                            href={`${
                              pageContext.navigationContext.children[
                                currIndex - 1
                              ].path
                            }`}
                          >
                            <div className={"leftArrow"}>
                              <svg
                                preserveAspectRatio="xMidYMid meet"
                                height="1em"
                                width="1em"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                stroke="currentColor"
                                className="_13gjrqj"
                              >
                                <g>
                                  <line x1="19" y1="12" x2="5" y2="12" />
                                  <polyline points="12 19 5 12 12 5" />
                                </g>
                              </svg>
                            </div>{" "}
                            <div className={"preRightWrapper"}>
                              <div className={"smallContent"}>
                                <span className="prevText">Previous</span>
                              </div>
                              <div className={"nextPreviousTitle"}>
                                <span>
                                  {
                                    pageContext.navigationContext.children[
                                      currIndex - 1
                                    ].title
                                  }
                                </span>
                              </div>
                            </div>
                          </Link>
                        ) : (
                          <div />
                        )}
                        {pageContext.navigationContext.children[
                          currIndex + 1
                        ] && (
                          <Link
                            className={"nextBtn"}
                            href={`${
                              pageContext.navigationContext.children[
                                currIndex + 1
                              ].path
                            }`}
                          >
                            <div className={"nextRightWrapper"}>
                              <div className={"smallContent"}>
                                <span className="nextText">Next</span>
                              </div>
                              <div className={"nextPreviousTitle"}>
                                <span>
                                  {
                                    pageContext.navigationContext.children[
                                      currIndex + 1
                                    ].title
                                  }
                                </span>
                              </div>
                            </div>
                            <div className={"rightArrow"}>
                              <svg
                                preserveAspectRatio="xMidYMid meet"
                                height="1em"
                                width="1em"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                stroke="currentColor"
                                className="_13gjrqj"
                              >
                                <g>
                                  <line x1="5" y1="12" x2="19" y2="12" />
                                  <polyline points="12 5 19 12 12 19" />
                                </g>
                              </svg>
                            </div>
                          </Link>
                        )}
                      </StyledNextPrevious>
                      {config.gitHubRepository && (
                        <Box display="flex">
                          <ReportAnIssueLink
                            href={getReportIssueHref(location)}
                            tracking={{
                              category: "article",
                              label: "report_an_issue",
                            }}
                          >
                            <Box
                              display="flex"
                              marginRight={1}
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <FaGithub />
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <p>{content.report_an_issue}</p>
                            </Box>
                          </ReportAnIssueLink>
                        </Box>
                      )}
                    </Box>
                    {pageContext.navigationContext.children.length > 0 && (
                      <MobileInlineNavigation
                        className={mobileBottomNavigationOpen ? "open" : ""}
                      >
                        <div>
                          <NavigationTree
                            lang={pageContext.lang}
                            ariaLabel={`${pageContext.navigationContext.title} subnavigation`}
                            items={pageContext.navigationContext.children}
                            path={`/${pageContext.navigationContext.key}`}
                            currentPathname={location.pathname}
                            autoScroll={false}
                          />
                        </div>
                        <Link
                          href="#"
                          aria-hidden="true"
                          tracking={{
                            label: "toggle_mobile_article_navigation_bottom",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setMobileBottomNavigationOpen(
                              !mobileBottomNavigationOpen
                            );
                          }}
                        >
                          {mobileBottomNavigationOpen && <FaChevronUp />}
                          {!mobileBottomNavigationOpen && <FaEllipsisH />}
                        </Link>
                      </MobileInlineNavigation>
                    )}
                  </MainContent>
                </PageContent>
              )}
            </Location>
            <Theme.Consumer>
              {({ theme, key }) => (
                <Footer theme={theme.palette.type} variant={key} />
              )}
            </Theme.Consumer>
          </Container>
        </Layout>
      )}
    />
  );
};

Article.propTypes = {
  pageContext: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    navigationContext: PropTypes.object.isRequired,
    lastUpdatedFormatted: PropTypes.string,
    lang: PropTypes.string.isRequired,
  }).isRequired,
};

export default Article;
